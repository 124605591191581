import ArtistSongsHead from './Head';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getAlbumDirectoryPath,
  getCurrentArtistId,
  getCurrentArtistName,
} from 'state/Artists/selectors';
import { getSiteUrl } from 'state/Config/selectors';

const mapStateToProps = createStructuredSelector({
  artistId: getCurrentArtistId,
  artistName: getCurrentArtistName,
  pagePath: getAlbumDirectoryPath,
  siteUrl: getSiteUrl,
});

export default connect(mapStateToProps)(ArtistSongsHead);
