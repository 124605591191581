import ArtistAlbums from './ArtistAlbums';
import Head from './Head';
import ListenInApp from 'components/ListenInApp';
import PageBody, { ViewName } from 'views/PageBody';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import SectionHeader from 'components/SectionHeader/SectionHeader';
import SimilarArtistAside from '../SimilarArtistAside';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { Album } from 'state/Albums/types';
import { getArtistUrl } from 'views/Artist/helpers';
import { STATION_TYPE } from 'constants/stationTypes';

type Props = {
  albumPlayback: boolean;
  albums: Array<Album>;
  artistId: number;
  artistName: string;
  customRadioEnabled: boolean;
  nextAlbumLink: string;
  requestAdditionalAlbums: () => void;
};

export default function Albums({
  artistId,
  artistName,
  albumPlayback,
  albums,
  nextAlbumLink,
  requestAdditionalAlbums,
  customRadioEnabled,
}: Props) {
  const translate = useTranslate();

  const artistUrl = getArtistUrl(artistId, artistName);

  const similar = (
    <SimilarArtistAside playedFrom={PLAYED_FROM.PROF_SONG_SIMILAR} />
  );

  const social = {
    seedId: artistId,
    seedType: STATION_TYPE.ARTIST,
    supportsConnect: false,
    url: artistUrl as string,
  };

  if (!artistId) return null;

  return (
    <>
      <Head />
      <ListenInApp seedId={artistId} seedType="artist" />
      <PageBody
        backLink={artistUrl as string}
        dataTest={ViewName.ArtistAlbums}
        more={similar}
        social={social}
        title={artistName}
      >
        <SectionHeader title={translate('Albums')} />
        <ArtistAlbums
          albumPlayback={albumPlayback}
          albums={albums}
          artistId={artistId}
          artistName={artistName}
          customRadioEnabled={customRadioEnabled}
          nextAlbumLink={nextAlbumLink}
          playedFrom={PLAYED_FROM.PROF_ARTIST_ALBUMS}
          requestAdditionalAlbums={requestAdditionalAlbums}
          singleRow={false}
          tilesInRow={3}
        />
      </PageBody>
    </>
  );
}
