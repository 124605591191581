import BR from 'primitives/Layout/BR';
import Bullet from 'components/Bullet';
import CatalogImage from 'components/MediaServerImage/CatalogImage';
import DummyTiles from '../DummyTiles';
import EmptyState from '../EmptyState';
import ExplicitLyrics from 'components/ExplicitLyrics';
import InfiniteScroll from 'react-infinite-scroller';
import logger, { CONTEXTS } from 'modules/Logger';
import NavLink from 'components/NavLink';
import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import PlayButtonContainer from 'components/Player/PlayButtonContainer';
import PlayButtonContainerPrimitive from 'components/Artist/PlayButtonContainer';
import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import Row from '../Row';
import SectionHeaderText from '../SectionHeaderText';
import ShouldShow from 'components/ShouldShow';
import Tile from 'components/Tile/Tile';
import Tiles from 'components/Tiles/Tiles';
import TilesImageWrapper from 'components/Tile/primitives/TilesImageWrapper';
import transport from 'api/transport';
import Truncate from 'components/Truncate';
import useMount from 'hooks/useMount';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { Album } from 'state/Albums/types';
import { Album as AlbumIcon } from 'styles/icons';
import { Fragment, useCallback, useState } from 'react';
import { getMyMusicAlbums } from 'state/MyMusic/services';
import { H4, TileSubtitle, TilesWrapper, TileTitle } from '../primitives';
import { MY_MUSIC_SUB_TYPE } from 'state/Playback/constants';
import { slugify } from 'utils/string';
import { STATION_TYPE } from 'constants/stationTypes';
import { TILE_RES } from 'components/MediaServerImage';
import { TILES_IN_ROW } from '../constants';
import { uniqBy } from 'lodash-es';

const PlayButton = PlayerStateProxy(PlayButtonContainer);

export type Props = {
  albumReceived: (albums: Array<Album>) => void;
  profileId: number | null;
  sessionId: string | null;
};

type AlbumsArray = Array<
  {
    count: number;
  } & Album
>;

function Albums({ albumReceived, profileId, sessionId }: Props) {
  const [albums, setAlbums] = useState<AlbumsArray>([]);
  const [hasReceivedAlbums, setHasReceivedAlbums] = useState<boolean>(false);
  const [nextPageKey, setNextPageKey] = useState<string | null | undefined>(
    undefined,
  );
  const translate = useTranslate();
  const hasMore = !hasReceivedAlbums || (hasReceivedAlbums && !!nextPageKey);
  const loadMore = useCallback(async () => {
    if (!hasMore) return;

    let albumsData: AlbumsArray = [];

    try {
      const res = await transport(
        getMyMusicAlbums({
          key: nextPageKey,
          limit: 30,
          profileId,
          sessionId,
        }),
      );
      albumsData = res?.data?.data ?? [];
      albumReceived(albumsData);
      const combinedAlbums = [...albums, ...albumsData];
      const uniqueAlbums = uniqBy(combinedAlbums, 'id');
      setAlbums(uniqueAlbums);
      setHasReceivedAlbums(true);
      setNextPageKey(res?.data?.links?.nextPageKey);
    } catch (e: any) {
      const errObj = e instanceof Error ? e : new Error(e);
      logger.error(CONTEXTS.YOUR_LIBRARY, errObj.message, {}, errObj);
    }
  }, [albumReceived, albums, hasMore, nextPageKey, profileId, sessionId]);

  // load albums
  useMount(() => {
    loadMore();
  });

  let tiles = [...DummyTiles({ noRoundTiles: true })];

  if (hasReceivedAlbums) {
    tiles = albums.map(
      ({ artistId, artistName, count, explicitLyrics, id, title }) => {
        const albumUrl = `/your-library/albums/${slugify(title)}-${id}/`;
        const artistUrl = `/artist/${slugify(artistName)}-${artistId}/`;

        const subtitle = (
          <TileSubtitle data-test={`${id}-subtitle`}>
            <NavLink to={artistUrl}>
              <Truncate lines={1}>{artistName}</Truncate>
            </NavLink>
            <Bullet />
            {translate('{count} song{plural}', {
              count: `${count || 0} `,
              plural: count === 1 ? '' : 's',
            })}
          </TileSubtitle>
        );

        const titleText = <Truncate lines={1}>{title}</Truncate>;

        const titleComponent = (
          <ShouldShow hiddenElement={titleText} shouldShow={explicitLyrics}>
            <TileTitle data-test={`${id}-title`}>
              {titleText}
              <ExplicitLyrics />
            </TileTitle>
          </ShouldShow>
        );

        const playButtonComponent = (
          <PlayButtonContainerPrimitive>
            <PlayButton
              additionalOpts={{ myMusicType: MY_MUSIC_SUB_TYPE.ALBUM }}
              className="play"
              myMusicType={MY_MUSIC_SUB_TYPE.ALBUM}
              seedId={id}
              stationId={id}
              stationType={STATION_TYPE.MY_MUSIC}
            />
          </PlayButtonContainerPrimitive>
        );

        const imageComponent = (
          <TilesImageWrapper>
            <CatalogImage
              alt={title}
              aspectRatio={1}
              background
              height={TILE_RES}
              id={id}
              type={STATION_TYPE.ALBUM}
              width={TILE_RES}
            />
          </TilesImageWrapper>
        );

        return (
          <Fragment key={id}>
            <Row
              imageComponent={imageComponent}
              playButtonComponent={playButtonComponent}
              subtitleComponent={subtitle}
              title={title}
              titleComponent={titleComponent}
              url={albumUrl}
            />
            <Tile
              key={id}
              noTileOnMobile
              subTitle={subtitle}
              tileDelay={0}
              tilesInRow={TILES_IN_ROW}
              title={title}
              titleSingleLine
              url={albumUrl}
            >
              {playButtonComponent}
              {imageComponent}
            </Tile>
          </Fragment>
        );
      },
    );
  }

  return (
    <>
      <H4 data-test="your-library-albums-title" hideHeader={tiles.length === 0}>
        <SectionHeaderText>{translate('Albums')} (A-Z)</SectionHeaderText>
      </H4>

      <ShouldShow shouldShow={tiles.length > 0 || !hasReceivedAlbums}>
        <BR />
        <TilesWrapper data-test="albums-tiles-wrapper">
          <InfiniteScroll hasMore={hasMore} loadMore={loadMore} pageStart={0}>
            <Tiles noTileOnMobile tilesInRow={TILES_IN_ROW}>
              {tiles}
            </Tiles>
          </InfiniteScroll>
        </TilesWrapper>
      </ShouldShow>

      <ShouldShow shouldShow={tiles.length === 0}>
        <EmptyState
          buttons={[
            <NavLink
              dataTest="albums-empty-link"
              key="albums-empty-link"
              to="/artist/"
            >
              <OutlinedButton>{translate('Browse Artists')}</OutlinedButton>
            </NavLink>,
          ]}
          icon={AlbumIcon}
          subtitle={translate('Need to find a great new album?')}
          title={translate('Any albums you save will show up here')}
        />
      </ShouldShow>
    </>
  );
}

export default Albums;
