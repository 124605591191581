import GenericHead from 'components/GenericHead';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { getScaledImageUrlById } from 'utils/url';
import { Helmet } from 'react-helmet';
import {
  metaResolveUrl as resolveUrl,
  translateKeywords,
} from 'utils/metaUtils';

type Props = {
  artistId: number;
  artistName: string;
  pagePath: string;
  siteUrl: string;
};

export default function Head({
  siteUrl,
  pagePath,
  artistId,
  artistName,
}: Props) {
  const translate = useTranslate();

  const description = translate(
    'Listen to music you love! Create a custom radio station based on your favorite album by {artistName} on iHeartRadio!',
    { artistName },
  );
  const keywords = translateKeywords(
    translate,
    'Music, Albums, Recent, New, Download, iHeartRadio, iHeart, Radio',
  );

  const title = translate('Stream Free Music from Albums by {artistName}', {
    artistName,
  });

  const url = resolveUrl(siteUrl, pagePath);

  return (
    <div>
      <GenericHead
        deeplink={`play/custom/artist/${artistId}`}
        description={description}
        image={getScaledImageUrlById(artistId, 'artist')}
        legacyDeeplink={`play/custom/artist/${artistId}`}
        metaDescription={description}
        ogType="profile"
        title={title}
        twitterCard="summary_large_image"
      />
      <Helmet
        meta={[
          {
            content: [artistName, ...keywords.split(',')] as any as string,
            name: 'keywords',
          },
          { content: 'audio/vnd.facebook.bridge', property: 'og:audio:type' },
          { content: url, property: 'og:audio' },
        ]}
      />
    </div>
  );
}
